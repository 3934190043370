import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import Pricing from '../components/pricing'

import Quiz from '../components/quiz';

const IndexPage = () => (
  <Layout>
    <div style={{textAlign: 'center'}}>
      <Quiz />
      <h1 className="section-header">Zinip Pricing Plans</h1>
      <Pricing />
      <br/><br/><br/><br/><br/>
      <h1 className="section-header">Zinip Videos</h1>
      <div class="auto-resizable-iframe" style={{marginBottom: '2rem'}}>
        <div>
          <iframe title="Never lose another recipe again!" src="https://www.youtube-nocookie.com/embed/b_QdBZmoyqc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <div class="auto-resizable-iframe" style={{marginBottom: '2rem'}}>
        <div>
          <iframe title="Winning with Zinip" width="560" height="315" src="https://www.youtube-nocookie.com/embed/SxKTZHQG3UI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <div class="auto-resizable-iframe" style={{marginBottom: '2rem'}}>
        <div>
          <iframe title="Digitize and organize your recipes" width="560" height="315" src="https://www.youtube-nocookie.com/embed/93Ytz_qDZWg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <br/><br/><br/>
      <h1 className="section-header">Zinip Features</h1>
      <ul className="item-list features col">
        <li>Organize all your recipes in a single digital space</li>
        <li>Share recipes with friends</li>
        <li>Import recipes from many websites</li>
        <li>Ask Alexa about your recipes</li>
      </ul>
      <ul className="item-list features col">
        <li>Auto-schedule meals from your favorite recipes</li>
        <li>Automatic shopping list</li>
        <li>Online grocery checkout Chrome Extension</li>
        <li>Learn how Zinip works with walkthrough tutorials</li>
      </ul>
      <br/><br/><br/>
    </div>
  </Layout>
)

export default IndexPage
