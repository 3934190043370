import React, { Component } from 'react';
import { Spring } from 'react-spring'

class Quiz extends Component{

  constructor(props){
    super(props);
    this.state = this.getSavedInitialState();

    this.organizationIndex = Math.floor(Math.random() * this.getOrganizationPhrases().length);
  }

  getSavedInitialState(){
    return {
      stage: 1,
      visited: this.getValue('visited', false),
      camp: this.getValue('camp', null),
    };
  }

  resetWebsite(){
    this.removeItem('camp');
    this.removeItem('visited');
    this.removeItem('stage');
    this.setState(this.getSavedInitialState());
    this.doFirstStep();
  }

  doFirstStep(){
    setTimeout(this.nextStage.bind(this), 2000);
    setTimeout(this.setValue.bind(this, 'visited', true), 2500);
  }

  componentDidMount(){
    this.doFirstStep();
  }

  setValue(key, value){
    if(typeof window !== 'undefined'){
      window.localStorage.setItem(key, value);
    }

    let data = {};
    data[key] = value;
    this.setState(data);
  }

  getValue(key, defaultValue){
    if(typeof window !== 'undefined'){
      let value = window.localStorage.getItem(key);
      if(value === null){
        return defaultValue;
      }
      return value;
    }
  }

  removeItem(key){
    if(typeof window !== 'undefined'){
      return window.localStorage.removeItem(key);
    }
  }

  nextStage(){
    let stage = this.getValue('stage', 1);
    let self = this;

    if(stage < 4){
      this.setState({
        stage: 2,
      });
      setTimeout(function(){
        self.setState({
          stage: 3,
        });
      }, 2000);
    }
    else{
      this.setState({
        stage: 4,
      });
    }
  }

  hello(){
    return (
      <Spring key="hello" from={{ opacity: 0, marginLeft: -1500}} to={{ opacity: 1, marginLeft: 0}}>
        {props => <div className="quiz-item" style={props}>{this.state.visited ? 'Welcome back.': 'Hello.'}</div>}
      </Spring>
    );
  }

  handleCampClick(value){
    this.setValue('camp', value);
    this.setValue('stage', 4);
  }

  kindsOfPeople(){
    return (
      <div className="quiz-item">
        <Spring key="kindsOfPeople" from={{ marginLeft: -1500}} to={{ marginLeft: 0}}>
          {props => <div style={{...props}}>When you REALLY think about it, there are 2 kinds of people: <br/><br/>ORGANIZED and DISORGANIZED</div>}
        </Spring>
        {
          this.state.stage === 3 ?
          <Spring key="kindsOfPeopleQuestion" from={{ marginLeft: -1500}} to={{ marginLeft: 0}}>
            {props =>
              <div style={{...props, marginTop: '1em'}}>
                <hr />
                <div>What are you like?</div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#fff',
                  flexDirection: 'row',
                  marginTop: '1em',
                }}>
                  <button className="quiz-button" onClick={this.handleCampClick.bind(this, 'organized')}>I'm Organized</button>
                  <button className="quiz-button" onClick={this.handleCampClick.bind(this, 'disorganized')}>I'm Disorganized</button>
                </div>
              </div>
            }
          </Spring> : null
        }
      </div>
    );
  }

  getOrganizationPhrases(){
    return [
      'Organization is your jam.',
      'You\'re an organization Jedi.',
      'Go forth and be organized.',
    ];
  }

  getOrganizationPhrase(){
    return this.getOrganizationPhrases()[this.organizationIndex];
  }

  campClaim(){
    if(this.state.camp === 'organized'){
      return (
        <div className="quiz-item">
          {this.getOrganizationPhrase()}
          <br/><br/>
          <div className="horizontal-flex">
            <a className="quiz-button" href="https://app.zinip.com/signin">Sign In</a> or <a className="quiz-button" style={{backgroundColor: '#FEE16C', color: '#333'}} href="https://app.zinip.com/signup">Create an Account</a>
          </div>
          <br/>
          {this.getResetWebsiteLink()}
        </div>
      );
    }

    return (
      <div className="quiz-item">
        So you're a disorganized person who loves spontaneity.
        <br/><br/>
        There's no need to ruin that fun here.
        <br/><br/>
        {this.getResetWebsiteLink()}
      </div>
    );
  }

  getResetWebsiteLink(){
    return <div style={{fontSize: '0.9rem', textDecoration: 'underline', cursor: 'pointer'}} onClick={this.resetWebsite.bind(this)}>Reset Website</div>
  }

  signInOrCreateAccount(){
    return (
      <div className="quiz-item">
        <div className="horizontal-flex">
          <a className="quiz-button" href="https://app.zinip.com/signin">Sign In</a> or <a className="quiz-button" style={{backgroundColor: '#FEE16C', color: '#333'}} href="https://app.zinip.com/signup">Create an Account</a>
        </div>
        <br/>
        {this.getResetWebsiteLink()}
      </div>
    );
  }

  getContent(){
    switch(this.state.stage){
      case 1: return this.hello();
      case 2:
      case 3: return this.kindsOfPeople();
      case 4: return this.campClaim();
      default: return this.signInOrCreateAccount();
    }
  }

  render(){
    return (
      <div style={{display: 'inline-block',height: '400px'}}>
        {this.getContent()}
      </div>
    );
  }
}

export default Quiz
