import React from 'react'

const Pricing = () => (
  <div style={{display: 'inline-block',}}>
    <div style={{
      width: '300px',
      borderRadius: '10px',
      boxShadow: '10px 10px 10px rgba(0, 0, 0, .5)',
      border: '1px solid rgba(0,0,0, 0.7)',
      backgroundColor: '#444',
      display: 'inline-block',
      margin: '1rem',
    }}>
      <div style={{
        color: '#fff',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        padding: '0.5em',
        fontSize: '1.5em',
        textAlign: 'center',
      }}>
        Homemaker Plan
      </div>
      <hr style={{
        color: '#000',
        height: '1px',
        margin: 0,
      }}/>
      <div style={{
        padding: '0.5em',
        color: '#fff',
        textAlign: 'center',
      }}>
        <div style={{
          fontSize: '3em',
        }}>
          Free*
        </div>
        <div style={{
          color: '#FEE16C',
          padding: '0.5em',
        }}>
          The best Zinip plan
        </div>
        <div style={{
          color: '#999',
          padding: '0.5rem',
        }}>
          You pay nothing. Zinip is gratis.
          <div style={{fontSize: '0.8rem', marginTop: '1em'}}>* Super free</div>
        </div>
      </div>
      <div style={{
        color: '#999',
        backgroundColor: '#ccc',
        padding: '0.5rem',
        textAlign: 'center',
      }}>
        <ul className="item-list">
          <li>Organize your recipes</li>
          <li>Import new recipes</li>
          <li>Auto-schedule your meals</li>
          <li>Automatic shopping list</li>
          <li>Online grocery Chome Extension</li>
          <li>Share recipes with friends</li>
          <li>Talk to Alexa about your recipes</li>
        </ul>
      </div>
      <a style={{
        backgroundColor: '#FEE16C',
        padding: '0.5em',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTop: '1px solid rgba(0,0,0, 0.7)',
        textAlign: 'center',
        color: '#444',
        fontSize: '1.2em',
        display: 'block',
        textDecoration: 'none',
      }}
      href="https://app.zinip.com/signup"
      >
        Get a free account
      </a>
    </div>



    <div style={{
      width: '300px',
      borderRadius: '10px',
      boxShadow: '10px 10px 10px rgba(0, 0, 0, .5)',
      border: '1px solid rgba(0,0,0, 0.7)',
      backgroundColor: '#444',
      display: 'inline-block',
      margin: '1rem',
    }}>
      <div style={{
        color: '#fff',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        padding: '0.5em',
        fontSize: '1.5em',
        textAlign: 'center',
      }}>
        Regular Person Plan
      </div>
      <hr style={{
        color: '#000',
        height: '1px',
        margin: 0,
      }}/>
      <div style={{
        padding: '0.5em',
        color: '#fff',
        textAlign: 'center',
      }}>
        <div style={{
          fontSize: '3em',
        }}>
          Free*
        </div>
        <div style={{
          color: '#FEE16C',
          padding: '0.5em',
        }}>
          Also the superior Zinip plan
        </div>
        <div style={{
          color: '#999',
          padding: '0.5rem',
        }}>
          Same as the homemaker plan.
          <div style={{fontSize: '0.8rem', marginTop: '1em'}}>* Also super free</div>
        </div>
      </div>
      <div style={{
        color: '#999',
        backgroundColor: '#ccc',
        padding: '0.5rem',
        textAlign: 'center',
      }}>
        <ul className="item-list">
          <li>Same as homemaker plan</li>
          <li>It just looks better to have 2 plans</li>
          <li>No differences between plans</li>
          <li>Both buttons do the same thing</li>
          <li>Homemakers are also regular people</li>
          <li>There's really only one plan</li>
          <li>Click below for a free account</li>
        </ul>
      </div>
      <a style={{
        backgroundColor: '#FEE16C',
        padding: '0.5em',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTop: '1px solid rgba(0,0,0, 0.7)',
        textAlign: 'center',
        color: '#444',
        fontSize: '1.2em',
        display: 'block',
        textDecoration: 'none',
      }}
      href="https://app.zinip.com/signup"
      >
        Get a free account
      </a>
    </div>
  </div>
)

export default Pricing
